import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ActivityGroupMap } from 'state-domains/domain/subscription';
import { buildConfigurationActivityGroupUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteConfigurationActivityGroup = (id: string): Observable<ActivityGroupMap> => {
    const obs = sendDeleteRequestWithXSRFToken(buildConfigurationActivityGroupUrl(id));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ActivityGroupMap>) => {
            const res = convertToCamel<ActivityGroupMap>(response);
            return observableOf(res);
        }),
    );
};
